
import { Connector } from "@/interfaces/connector";
import { User } from "@/interfaces/user";
import { SelectItem } from "@/interfaces/vuetify";
import { ConnectorService } from "@/services/connectorService";
import { UserService } from "@/services/userService";
import { Component, Emit, Vue } from "vue-property-decorator";

@Component
export default class Profile extends Vue {
    connectors: Connector[] = [];

    public async mounted(): Promise<void> {
        this.connectors = await ConnectorService.getAllConnectors(
            this.$store.state.token
        );
    }

    public get targets(): SelectItem[] {
        return this.connectors.map(connector => {
            return {
                text: `${connector.type}:${connector.name}`,
                value: connector.id,
            };
        });
    }

    public get user(): User | null {
        return this.$store.state?.user;
    }

    public logout(): void {
        this.$store.commit("logout");
        this.$router.push("/");
        this.close();
    }

    public async updateUser(): Promise<User | undefined> {
        if (!this.user) return;

        const updated = await UserService.updateMe(
            this.user,
            this.$store.state.token
        );
        if (!updated.id) {
            this.$store.commit("showSnackbar", {
                color: "error",
                text: updated,
            });
            return;
        }
        this.$store.commit("showSnackbar", {
            color: "success",
            text: "User Profil aktualisiert",
        });
        this.close();
        return updated;
    }

    @Emit()
    public close(): boolean {
        return true;
    }
}
