import { render, staticRenderFns } from "./Login3d.vue?vue&type=template&id=14ffe639&scoped=true&"
import script from "./Login3d.vue?vue&type=script&lang=ts&"
export * from "./Login3d.vue?vue&type=script&lang=ts&"
import style0 from "./Login3d.vue?vue&type=style&index=0&id=14ffe639&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14ffe639",
  null
  
)

export default component.exports