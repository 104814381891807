
import { Component, Vue } from "vue-property-decorator";
import { UserService } from "@/services/userService";
import { AuthService } from "@/services/authService";

@Component
export default class Login3d extends Vue {
    loading: boolean = false;
    email: string = "";
    password: string = "";
    passwordForgotten: boolean = false;
    fullName: string = "";
    checkbox: boolean = false;
    public mounted(): void {
        if (this.$store.getters.isAuthenticated) {
            this.$router.push("/dashboard");
        }
    }

    async login(): Promise<void> {
        this.loading = true;
        const response = await AuthService.login(this.email, this.password);
        this.loading = false;
        if (response.access_token) {
            this.$store.commit("setToken", response.access_token);
            const user = await UserService.getMe(response.access_token);
            this.$store.commit("setUser", { user: user });
            let target: string = "/dashboard";
            if (this.$route.query.redirect) {
                target = this.$route.query.redirect.toString();
            }
            this.$router.push(target);
        } else {
            this.$store.commit("showSnackbar", {
                color: "error",
                text: response,
            });
        }
    }
    async passwordReset(): Promise<void> {
        try {
            await AuthService.forgotPassword(this.email);
            this.$store.commit("showSnackbar", {
                color: "success",
                text: "Email versendet!",
            });
        } catch (error) {
            this.$store.commit("showSnackbar", {
                color: "error",
                text: error,
            });
        }
        this.passwordForgotten = false;
    }
    async register(): Promise<void> {
        this.loading = true;
        const validEmail = /^$|^[\w.-]+@[\w.-]+$/gi.test(this.email);

        if (!validEmail || !this.password || !this.fullName) {
            this.$store.commit("showSnackbar", {
                color: "error",
                text: "Bitte geben Sie eine gültige E-Mail Adresse & Namen an!",
            });
            return;
        }
        const response = await AuthService.register(
            this.email,
            this.fullName,
            this.password
        );
        this.loading = false;
        if (response.status == 201) {
            this.$router.push("/registration_complete");
        } else {
            this.$store.commit("showSnackbar", {
                color: "error",
                text: response.data,
            });
        }
    }
}
