
import { Component, Vue } from "vue-property-decorator";
import Login from "@/components/Login.vue";
import Login3d from "@/components/customComponents/Login3d.vue";

@Component({
    components: {
        Login,
        Login3d,
    },
})
export default class Home extends Vue {}
