import Axios from "axios";
import request from "axios";

import { Connector, ConnectorCreate } from "@/interfaces/connector";

export class ConnectorService {
    public static async createConnector(
        connector: ConnectorCreate,
        token: string
    ): Promise<{
        data: Connector;
        status: number;
    }> {
        const uri = `/api/connectors`;
        try {
            const response = await Axios.post(uri, connector, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response;
        } catch (error: unknown) {
            if (request.isAxiosError(error) && error.response) {
                return error.response.data.detail;
            } else {
                throw Error("Unknown Error");
            }
        }
    }

    public static async getAllConnectors(token: string): Promise<Connector[]> {
        const uri = `/api/connectors/all`;
        const response = await Axios.get(uri, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    }

    public static async getConnectorById(
        connectorId: string,
        token: string
    ): Promise<Connector> {
        const uri = `/api/connectors/${connectorId}`;
        const response = await Axios.get(uri, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    }

    public static async deleteConnector(
        connectorId: string,
        token: string
    ): Promise<void> {
        const uri = `/api/connectors/by_id/${connectorId}`;
        await Axios.delete(uri, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return;
    }
}
