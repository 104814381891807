import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import store from "../store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: { title: "stackscan" },
    },
    // {
    //     path: "/register",
    //     name: "Register",
    //     component: () =>
    //         import(/* webpackChunkName: "register" */ "../views/Register.vue"),
    //     meta: { title: "stackscan", requiresAuth: false },
    // },
    {
        path: "/registration_complete",
        name: "RegistrationComplete",
        component: () =>
            import(
                /* webpackChunkName: "registrationcomplete" */ "../views/RegistrationComplete.vue"
            ),
        meta: { title: "stackscan", requiresAuth: false },
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: () =>
            import(
                /* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"
            ),
        meta: { title: "stackscan", requiresAuth: true },
    },
    {
        path: "/verify",
        props: route => ({
            token: route.query.token,
        }),
        name: "VerificationComplete",
        component: () =>
            import(
                /* webpackChunkName: "verificationcomplete" */ "../views/VerificationComplete.vue"
            ),
        meta: { title: "stackscan", requiresAuth: false },
    },
    {
        path: "/passwortReset",
        props: route => ({
            token: route.query.token,
        }),
        name: "PasswortReset",
        component: () =>
            import(
                /* webpackChunkName: "PasswortReset" */ "../views/PasswortReset.vue"
            ),
        meta: { title: "stackscan", requiresAuth: false },
    },
    {
        path: "/process",
        props: true,
        name: "Process",
        component: () =>
            import(/* webpackChunkName: "Process" */ "../views/Process.vue"),
        meta: { title: "stackscan", requiresAuth: true },
    },
    {
        path: "/scans",
        props: true,
        name: "Scans",
        component: () =>
            import(/* webpackChunkName: "Scans" */ "../views/Scans.vue"),
        meta: { title: "stackscan", requiresAuth: true },
    },
    {
        path: "/scandetail/:scanId",
        props: true,
        name: "ScanDetail",
        component: () =>
            import(
                /* webpackChunkName: "ScanDetail" */ "../views/ScanDetail.vue"
            ),
        meta: { title: "stackscan", requiresAuth: true },
    },
    {
        path: "/tags",
        props: true,
        name: "Tags",
        component: () =>
            import(/* webpackChunkName: "Tags" */ "../views/Tags.vue"),
        meta: { title: "stackscan", requiresAuth: true },
    },
    {
        path: "/connectors",
        props: true,
        name: "Connectors",
        component: () =>
            import(
                /* webpackChunkName: "Connectors" */ "../views/Connectors.vue"
            ),
        meta: { title: "stackscan", requiresAuth: true },
    },
    {
        path: "/split-profiles",
        props: true,
        name: "SplitProfiles",
        component: () =>
            import(
                /* webpackChunkName: "profiles" */ "../views/SplitProfiles.vue"
            ),
        meta: { title: "stackscan", requiresAuth: true },
    },
    {
        path: "/docs",
        beforeEnter() {
            location.href = `/docs/`;
        },
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: to => {
        if (to.hash) {
            return { selector: to.hash };
        } else {
            return { x: 0, y: 0 };
        }
    },
});
const DEFAULT_TITLE = "stackscan";
router.afterEach(to => {
    Vue.nextTick(() => {
        document.title = to.meta?.title || DEFAULT_TITLE;
    });
});

router.beforeEach(async (to, from, next) => {
    // routeguard
    const isUserLoggedIn = store.getters.isAuthenticated;
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // if token is expired and route protected, push back to login

        if (!isUserLoggedIn) {
            // User is not logged in but trying to open page which requires
            next({
                path: "/",
                query: { redirect: to.fullPath },
            });
            store.commit("showSnackbar", {
                text: "Session abgelaufen",
                color: "error",
            });
        } else {
            // User is logged in and trying to open page which requires auth
            next();
        }
    } else if (to.name === "Login" && isUserLoggedIn) {
        // User logged in but still trying to open login page
        next({ name: "Home" });
    } else {
        // User is trying to open page which doesn't require auth
        next();
    }
});
export default router;
