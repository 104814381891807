
import { Component, Vue } from "vue-property-decorator";

import { User } from "../interfaces/user";

interface NavItem {
    icon: string;
    title: string;
    link: string;
    permission: boolean;
}
@Component
export default class NavigationList extends Vue {
    model: number = 0;
    public get items(): NavItem[] {
        return [
            {
                icon: "mdi-home",
                title: "Home",
                link: "/dashboard",
                permission: true,
            },
            {
                icon: "mdi-cube-scan",
                title: "Verarbeitung",
                link: "/process",
                permission: true,
            },
            {
                icon: "mdi-scanner",
                title: "Scans",
                link: "/scans",
                permission: true,
            },
            {
                icon: "mdi-tag",
                title: "Tags",
                link: "/tags",
                permission: true,
            },
            {
                icon: "mdi-scissors-cutting",
                title: "Split-Profiles",
                link: "/split-profiles",
                permission: true,
            },
            {
                icon: "mdi-upload",
                title: "Connectors",
                link: "/connectors",
                permission: true,
            },
            {
                icon: "mdi-api",
                title: "Swagger",
                link: "/docs",
                permission: true,
            },
        ];
    }

    public get user(): User | null {
        return this.$store.state.user;
    }

    public get availableItems(): NavItem[] {
        return this.items.filter(i => i.permission);
    }
}
